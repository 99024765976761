import React from 'react';
import { makeStyles } from '@mui/styles';
import sidebarImg from '../resources/images/styleA-left.png';

const useStyles = makeStyles(() => ({
  image: {
    height: '100vh',
    maxHeight: '100vh',
    margin: 'auto',
  },
}));

const SidebarImage = () => {
  const classes = useStyles();

  return (<img src={sidebarImg} className={classes.image} alt="awayspeed sidebar" />);
};

export default SidebarImage;
